import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'

class GatheringSetting {
  epaymentDataSchema (data = []) {
    const indexMap = {}
    return data.map(item => {
      const data = {
        typeName: item.description,
        payType: item.type,
        status: item.enabled,
        linkedPayType: null
      }
      indexMap[data.payType] = data
      return data
    }).map(item => {
      if (item.payType === 6) {
        item.typeName = '支付宝'
        item.status = item.status || ((indexMap[101] || {}).status || false)
        item.linkedPayType = 101
      }
      if (item.payType === 8) {
        item.typeName = '微信'
        item.status = item.status || ((indexMap[102] || {}).status || false)
        item.linkedPayType = 102
      }
      return item
    }).filter(item => {
      return !(item.payType === 101 || item.payType === 102)
    })
  }

  isPaymentAllClose () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      resolve((await this.getDataList()).filter(item => {
        return item.payType === 6 || item.payType === 8
      }).map(item => {
        return item.status
      }).reduce((res, next) => {
        return res || next
      }, false) === false)
    })
  }

  getDataList () {
    return api.get(`${apiUrl}/sales/payment/pay-type-config`).then(res => {
      const data = res.data || []
      return this.epaymentDataSchema(data)
    }).catch(() => {
      return []
    })
  }

  // 开启支付方式
  submitEpayment ({ payType }) {
    return api.post(`${apiUrl}/sales/payment/enable-pay-type?payType=${payType}`).then(res => {
      return res
    })
  }

  // 关闭支付方式
  closeEpayment ({ payType }) {
    return api.post(`${apiUrl}/sales/payment/disable-pay-type?payType=${payType}`).then(res => {
      return res
    })
  }

  // 获取设置模式
  getPattern () {
    return api.get(`${apiUrl}/sales/payment/collection-mode`).then(res => {
      const data = res.data
      return data
    })
  }

  // 设置模式
  settingPattern (data) {
    return api.post(`${apiUrl}/sales/payment/collection-mode`, {
      mode: data
    }).then(res => {
      return res
    })
  }

  // 获取扫码收款审核通过状态
  getStatus () {
    return api.get(`${apiUrl}/cebcollection/states`).then(res => {
      const data = res.data.data || {}
      return data.stateType === 4
    })
  }
}

export default GatheringSetting

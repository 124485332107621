<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="选择模式" />
    </template>
    <van-cell-group>
      <van-cell clickable
                :title="item.title"
                :label="item.desc"
                :key="item.label"
                center
                @click="toggle(index, item)"
                v-for="(item, index) in gatheringPattern"
      >
        <template #right-icon>
          <van-checkbox :name="item" ref="checkboxes" :value="item.value" checked-color="#ff6a3c" />
        </template>
      </van-cell>
    </van-cell-group>
  </page-view>
</template>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import ItemModel from '@/model/setting/epayment-gather'
import {
  Checkbox,
  Cell,
  CellGroup,
  Dialog
} from 'vant'

export default {
  name: 'EpaymentGatherPattern',
  data () {
    const itemModel = new ItemModel()
    return {
      itemModel,
      gatheringPattern: [{
        title: '收款模式',
        desc: '通过扫码、收款码完成支付宝、微信的收款',
        value: false,
        label: 1
      }, {
        title: '记账模式',
        desc: '已通过其它方式完成收款，仅记录金额',
        value: false,
        label: 0
      }, {
        title: '双重模式',
        desc: '同时支持收款模式和记账模式',
        value: false,
        label: 2
      }],
      inputRadio: 'radio',
      havePaymentOpened: false,
      allClose: false
    }
  },
  components: {
    PageView,
    NavBar,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [Dialog.name]: Dialog
  },
  created () {
  },
  async mounted () {
    this.getPattern()
    this.havePaymentOpened = await this.itemModel.getStatus()
    this.allClose = await this.itemModel.isPaymentAllClose()
  },
  beforeDestroy () {
  },
  methods: {
    toggle (index, item) {
      if (item.label === 1) {
        if (!this.havePaymentOpened) {
          Dialog.confirm({
            title: '提示',
            message: '您未开通微信、支付宝收款！',
            confirmButtonText: '立即开通'
          }).then(() => {
            this.$router.replace('/acquire-intro/progress')
          }).catch(_ => {})
          return
        }
        if (this.allClose) {
          Dialog('微信、支付宝收款未开启')
          return
        }
      }
      this.$refs.checkboxes[index].toggle()
      this.itemModel.settingPattern(item.label)
      this.$bus.$emit('goToGathering', item.label)
      this.$router.back()
    },
    getPattern () {
      this.itemModel.getPattern().then(res => {
        this.gatheringPattern.map(item => {
          item.value = item.label === res
        })
      })
    }
  },
  computed: {},
  watch: {}
}
</script>
<style lang="less" scoped>
.right{
  display: flex;
  justify-content: flex-end;
}
</style>
